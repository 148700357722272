// npm
import React from 'react'
import { graphql, PageProps } from 'gatsby'

// root
import { pageContextProps } from '../../gatsby-node'

// components
import ArtistLandingLayout from '@templates/layouts/ArtistLandingLayout'

interface Props {
  data: Queries.ArtistCategoryLandingQuery
  pageContext: PageProps & pageContextProps
}

const ArtistCategoryLanding = ({ data, pageContext }: Props) => {
  return (
    <ArtistLandingLayout
      artists={data.allDatoCmsArtist.edges}
      categoryData={data.datoCmsArtworkCategory}
      pageContext={pageContext}
    />
  )
}

export const query = graphql`
  query ArtistCategoryLanding($categoryPageId: String!) {
    datoCmsArtworkCategory(originalId: { eq: $categoryPageId }) {
      artistText
      name
    }
    allDatoCmsArtist(
      filter: {
        featuredArtist: { eq: true }
        category: { elemMatch: { originalId: { eq: $categoryPageId } } }
      }
      sort: { fields: surname, order: ASC }
    ) {
      edges {
        node {
          ...artistBase
        }
      }
    }
  }
`

export default ArtistCategoryLanding
